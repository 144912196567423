/*======================================
    Buttons
======================================*/

%btn {
    --btn-bg-color: var(--orange);
    --btn-bg-color-hover: var(--orange);
    --btn-border-color: var(--orange);
    --btn-border-color-hover: var(--orange);
    --btn-border-radius: 100px;
    --btn-text-color: var(--brown);
    --btn-text-color-hover: var(--brown);
    --btn-fontweight: var(--fontweight-semibold);
    --btn-focus-outline-color: var(--orange);
    --btn-icon-image-home: url('../images/ui/home-dark.svg');
    --btn-icon-image-download: url('../images/ui/download-dark.svg');
    --btn-icon-image-user: url('../images/ui/user-dark.svg');
    --btn-icon-image-skip: url('../images/ui/arrow-curve-down-dark.svg');

    align-items: center;
    background-color: var(--btn-bg-color);
    border-radius: var(--btn-border-radius);
    border: 1px solid var(--btn-border-color);
    color: var(--btn-text-color);
    cursor: pointer;
    display: inline-flex;
    font-size: rem(16px);
    font-weight: var(--btn-fontweight);
    line-height: 1;
    margin: 0 1em 1em 0;
    padding: 0.75em 1.25em 0.6875em;
    text-align: center;
    text-decoration: none;
    transition: background-color 250ms ease, border-color 250ms ease, color 250ms ease;
    //white-space: nowrap;

    &:hover,
    &:focus {
        background-color: var(--btn-bg-color-hover);
        border-color: var(--btn-border-color-hover);
        color: var(--btn-text-color-hover);
    }
    
    &:focus {
        outline: 2px solid var(--btn-focus-outline-color);
        outline-offset: 2px;
    }
}

%btn-white {
    --btn-bg-color: var(--white);
    --btn-bg-color-hover: var(--white);
    --btn-border-color: var(--white);
    --btn-border-color-hover: var(--white);
    --btn-text-color: var(--brown);
    --btn-text-color-hover: var(--brown);
    --btn-icon-image-home: url('../images/ui/home-dark.svg');
    --btn-icon-image-download: url('../images/ui/download-dark.svg');
    --btn-icon-image-external-link: url('../images/ui/link-external-dark.svg');
    --btn-icon-image-user: url('../images/ui/user-dark.svg');
    --btn-icon-image-search: url('../images/ui/search-dark.svg');
    --btn-icon-image-skip: url('../images/ui/arrow-curve-down-dark.svg');
}

%btn-gray-100 {
    --btn-bg-color: var(--gray-100);
    --btn-bg-color-hover: var(--gray-300);
    --btn-border-color: var(--gray-100);
    --btn-border-color-hover: var(--gray-300);
    --btn-text-color: var(--brown);
    --btn-text-color-hover: var(--brown);
    --btn-icon-image-home: url('../images/ui/home-dark.svg');
    --btn-icon-image-download: url('../images/ui/download-dark.svg');
    --btn-icon-image-external-link: url('../images/ui/link-external-dark.svg');
    --btn-icon-image-user: url('../images/ui/user-dark.svg');
    --btn-icon-image-search: url('../images/ui/search-dark.svg');
    --btn-icon-image-skip: url('../images/ui/arrow-curve-down-dark.svg');
}

%btn-brown {
    --btn-bg-color: var(--brown);
    --btn-bg-color-hover: var(--brown);
    --btn-border-color: var(--brown);
    --btn-border-color-hover: var(--brown);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-icon-image-home: url('../images/ui/home-light.svg');
    --btn-icon-image-download: url('../images/ui/download-light.svg');
    --btn-icon-image-external-link: url('../images/ui/link-external-light.svg');
    --btn-icon-image-user: url('../images/ui/user-light.svg');
    --btn-icon-image-search: url('../images/ui/search-light.svg');
    --btn-icon-image-skip: url('../images/ui/arrow-curve-down-light.svg');
}

%btn-outline-brown {
    --btn-bg-color: transparent;
    --btn-bg-color-hover: transparent;
    --btn-border-color: var(--brown);
    --btn-border-color-hover: var(--brown);
    --btn-text-color: var(--brown);
    --btn-text-color-hover: var(--brown);
    --btn-icon-image-home: url('../images/ui/home-dark.svg');
    --btn-icon-image-download: url('../images/ui/download-dark.svg');
    --btn-icon-image-external-link: url('../images/ui/link-external-dark.svg');
    --btn-icon-image-user: url('../images/ui/user-dark.svg');
    --btn-icon-image-search: url('../images/ui/search-dark.svg');
    --btn-icon-image-skip: url('../images/ui/arrow-curve-down-dark.svg');
}

%btn-outline-white {
    --btn-bg-color: transparent;
    --btn-bg-color-hover: transparent;
    --btn-border-color: var(--white);
    --btn-border-color-hover: var(--white);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-icon-image-home: url('../images/ui/home-light.svg');
    --btn-icon-image-download: url('../images/ui/download-light.svg');
    --btn-icon-image-external-link: url('../images/ui/link-external-light.svg');
    --btn-icon-image-user: url('../images/ui/user-light.svg');
    --btn-icon-image-search: url('../images/ui/search-light.svg');
    --btn-icon-image-skip: url('../images/ui/arrow-curve-down-light.svg');
}

%btn-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    display: flex;
    flex: 0 0 auto;
    height: 1.1em;
    margin: auto auto auto 0.5em;
    width: 1.1em;
}

.btn {
    @extend %btn;
    
    &-white {
        @extend %btn-white;
    }

    &-brown {
        @extend %btn-brown;
    }
    
    &-gray-100 {
        @extend %btn-gray-100;
    }
    
    &-outline-brown {
        @extend %btn-outline-brown;
    }
    
    &-outline-white {
        @extend %btn-outline-white;
    }

    &:hover,
    &:focus {
        .btn-icon--plus {
            &::before,
            &::after {
              left: 65%;
              height: 0.65em;
            }
            
            &::before {
              transform: rotate(45deg);
              top: -0.0625em;
            }
            
            &::after {
              transform: rotate(-45deg);
              bottom: -0.0625em;
            }
        }
    }
    
    .btn-icon {
        @extend %btn-icon;
    }
    
    .btn-icon--download {
        background-image: var(--btn-icon-image-download);
    }
    
    .btn-icon--external-link {
        background-image: var(--btn-icon-image-external-link);
    }
    
    .btn-icon--home {
        background-image: var(--btn-icon-image-home);
    }
    
    .btn-icon--user {
        background-image: var(--btn-icon-image-user);
    }
    
    .btn-icon--search {
       background-image: var(--btn-icon-image-search);
    }
    
    .btn-icon--skip {
       background-image: var(--btn-icon-image-skip);
    }
      
    .btn-icon--plus {
        background-color: var(--btn-text-color);
        border-radius: 0.0625em;
        height: 0.125em;
        position: relative;
        transition: all 0.125s ease-out;
        width: 0.9em;

        &::before,
        &::after {
            border-radius: 0.0625em;
            display: block;
            content: '';
            height: 0.5em;
            width: 0.125em;
            left: 50%;
            position: absolute;
            background-color: var(--btn-text-color);
            transform: translateX(-0.0625em);
            transition: all 0.2s ease;
        }
    
        &::before {
            top: 0;
            transform-origin: center center;
        }
    
        &::after {
            bottom: 0;
            transform-origin: center center;
        }
    }
    
    &.btn-label-hidden {
        height: 44px;
        width: 44px;
        justify-content: center;
        
        .btn-label {
            @extend %visually-hidden;
        }
        .btn-icon {
            margin: auto 0;
        }
    }
    
    &.btn--view-more {
        margin: 0 auto;
    }
    

    &-search {
        background-color: var(--btn-border-color);
        background-image: url('../images/ui/search-light.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 1.5em;
        border: 0;
        min-width: 3rem;
        overflow: hidden;
        text-indent: -9999px;
    }

    .centered & {
        margin: 0 0.5rem 0.5rem;
    }

    &[disable],
    &.slick-disabled {
        --btn-bg-color: var(--gray-400);
        --btn-border-color: var(--gray-600);
        --btn-text-color: var(--gray-600);
        --btn-bg-color-hover: var(--gray-400);
        --btn-border-color-hover: var(--gray-400);
        --btn-text-color-hover: var(--gray-600);

        cursor: default;
        pointer-events: none;
        touch-action: none;
    }
}
