/* stylelint-disable */
@import 'https://use.typekit.net/axq3sgn.css';
@import 'global/variables';
@import 'global/a11y';
@import 'global/buttons';
@import 'global/bg';

body {
    background-color: var(--brown);
    font-family: var(--font-sans);
    color: var(--white);
    font-weight: var(--fontweight-normal);
    
    background-blend-mode: multiply;
    background-image: url('../images/deco/fao-ornament-brown-20pct.svg');
    background-position: calc(50% - 365px) center;
    background-repeat: no-repeat;
    background-size: 1600px auto;
}

.login {
    color: var(--brown);
    position: relative;
    z-index: 2;
    
    a {
        &:focus {
            box-shadow: none;
        }
    }

    h1 a,
    .wp-login-logo a {
        background-color: transparent;
        background-image: url('../images/logo/fao-light.svg');
        background-position: center center;
        background-size: contain;
        border: none;
        color: var(--white);
        height: 100px;
        padding: 24px;
        width: 270px;
        
        &:hover {
            color: var(--white);
        }
        
        &:focus {
            color: var(--white);
            outline: 2px solid var(--orange);
            outline-offset: 2px;
        }
    }

    #loginform {
        color: var(--brown);

        input {
            &[type="text"],
            &[type="password"] {
                box-shadow: none !important;

                &:focus {
                    box-shadow: none !important;
                    outline: 2px solid var(--orange);
                }
            }
        }
    }

    form {
        border-radius: 0.25rem;
        position: relative;

        .button-primary {
            @extend %btn;
            margin: 0;
            
            &:focus {
                box-shadow: none;
            }
        }
    }

    #backtoblog,
    #nav,
    .privacy-policy-page-link {
        a {
            color: var(--white);

            &:hover{
                color: var(--orange);
            }
            
            &:focus {
                color: var(--white);
                outline: 2px solid var(--orange);
                outline-offset: 2px;
            }
        }
    }

    #login_error,
    .message,
    .success {
        border-color: var(--red-600);
        color: var(--gray-900);
    }
}
