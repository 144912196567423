/*======================================
    Accessibility
======================================*/

// Accessibly hide elements
%visually-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visually-hidden,
.screen-reader-text,
.sr-only {
    @extend %visually-hidden;
}

// Skip to content link
.skip-to-content {
    background-color: var(--black-90pct);
    border: 2px solid var(--gray-600);
    color: var(--white);
    display: inline-block;
    font-weight: var(--fontweight-bold);
    left: 2px;
    margin: 0 auto;
    padding: 0.75em 2em;
    position: absolute;
    top: -100px;
    transition: top 250ms ease;
    z-index: 9999;

    &:focus {
        outline: 2px solid var(--orange);       
        top: 2px;
    }
}

// Remove animations if system-wide preference set
@media (prefers-reduced-motion: reduce) {
    * {
        -webkit-animation-duration: .01ms !important;
        animation-duration: .01ms !important;
        -webkit-animation-iteration-count: 1 !important;
        animation-iteration-count: 1 !important;
        transition-duration: .01ms !important;
        scroll-behavior:auto !important
    }
}
