// BG colors
[class*="has-bg-color--"] {
	background-color: var(--section-background-color);
}

.has-bg-color--white {
	--section-background-color: var(--white);
	
	+ & {
		padding-top: 0;
	}
}

.has-bg-color--gray-100 {
	--section-background-color: var(--gray-100);
	
	+ & {
		padding-top: 0;
	}
}

.has-bg-color--gray-200 {
	--section-background-color: var(--gray-200);
	
	+ & {
		padding-top: 0;
	}
}

.has-bg-color--gray-300 {
	--section-background-color: var(--gray-300);
	
	+ & {
		padding-top: 0;
	}
}
	
.has-bg-color--brown {
	--section-background-color: var(--brown);
}

.has-bg-color--blue-light {
	--section-background-color: var(--blue-light);
}

.has-bg-color--orange {
	--section-background-color: var(--orange);
	
	a:not([class]) {
		--link-color: var(--brown);
		--link-color-hover: var(--brown);
		
		&:focus {
			outline-color: var(--white);
		}
	}
	
	.btn {
		&:focus {
			outline-color: var(--white);
		}
	}
}

.has-bg-color--orange-dark {
	--section-background-color: var(--orange-dark);
	
	a {
		&:focus {
			outline-color: var(--white);
		}
	}
}

.has-bg-color--green-light {
	--section-background-color: var(--green-light);
}

.has-bg-color--green-dark {
	--section-background-color: var(--green-dark);
}

.has-bg-color--yellow {
	--section-background-color: var(--yellow);
}

.has-bg-color--purple {
	--section-background-color: var(--purple);
}

.has-bg-color--blue-dark {
	--section-background-color: var(--blue-dark);
}

.has-bg-color--teal {
	--section-background-color: var(--teal);
}