:root {
    // Neutrals
    --black: rgba(0, 0, 0, 1);
    --black-5pct: rgba(0, 0, 0, 0.05);
    --black-10pct: rgba(0, 0, 0, 0.1);
    --black-20pct: rgba(0, 0, 0, 0.2);
    --black-30pct: rgba(0, 0, 0, 0.3);
    --black-40pct: rgba(0, 0, 0, 0.4);
    --black-50pct: rgba(0, 0, 0, 0.5);
    --black-60pct: rgba(0, 0, 0, 0.6);
    --black-70pct: rgba(0, 0, 0, 0.7);
    --black-80pct: rgba(0, 0, 0, 0.8);
    --black-90pct: rgba(0, 0, 0, 0.9);

    --gray-100: rgba(248, 246, 240, 1);
    --gray-200: rgba(242, 239, 232, 1);
    --gray-300: rgba(238, 232, 220, 1);
    --gray-400: rgba(169, 169, 169, 1);
    --gray-600: rgba(87, 88, 90, 1);
    --gray-700: rgba(40, 40, 40, 1);

    --white: rgba(255, 255, 255, 1);
    --white-5pct: rgba(255, 255, 255, 0.05);
    --white-10pct: rgba(255, 255, 255, 0.1);
    --white-20pct: rgba(255, 255, 255, 0.2);
    --white-30pct: rgba(255, 255, 255, 0.3);
    --white-40pct: rgba(255, 255, 255, 0.4);
    --white-50pct: rgba(255, 255, 255, 0.5);
    --white-60pct: rgba(255, 255, 255, 0.6);
    --white-70pct: rgba(255, 255, 255, 0.7);
    --white-80pct: rgba(255, 255, 255, 0.8);
    --white-90pct: rgba(255, 255, 255, 0.9);

    // Theme
    // Primary
    --brown: rgba(82, 65, 55, 1);
    --brown-50pct: rgba(82, 65, 55, 0.5);
    --brown-dark: rgba(59, 44, 36, 1);
    --orange: rgba(255, 162, 42, 1);
    --orange-dark: rgba(233, 121, 49, 1);
    // Secondary
    --blue-light: rgba(133, 213, 218, 1);
    --green-light: rgba(133, 221, 153, 1);
    --green-dark: rgba(12, 89, 89, 1);
    --yellow: rgba(255, 214, 76, 1);
    --purple: rgba(115, 52, 109, 1); // Guersney Co
    --teal: rgba(27, 146, 153, 1); // Noble Co
    --blue-dark: rgba(17, 124, 181, 1); // Lawrence Co

    // Background
    --bg-gray-100: rgba(248, 246, 240, 1);
    --bg-gray-200: rgba(242, 239, 232, 1);
    --bg-gray-300: rgba(238, 232, 220, 1);
    
    // Shades 
    --blue-light-shaded: rgba(124, 198, 203, 0.3);
    --brown-shaded: rgba(71, 55, 46, 0.3);
    --green-light-shaded: rgba(124, 205, 142, 0.3);
    --green-dark-shaded: rgba(11, 83, 83, 0.3);
    --orange-shaded: rgba(237, 160, 59, 0.3);
    --orange-dark-shaded: rgba(233, 121, 49, 0.3);
    --yellow-shaded: rgba(237, 199, 71, 0.3);
    --purple-shaded: rgba(102, 44, 97, 0.3); // Guersney Co
    --teal-shaded: rgba(27, 146, 153, 0.3); // Noble Co
    --blue-dark-shaded: rgba(17, 124, 181, 0.3); // Lawrence Co
   
    --blue-accent: #2ca0ce;

    // Gradients
    --grad-gray: linear-gradient(135deg, var(--gray-100) 0%, var(--gray-200) 100%);


    // Fonts, weights
    --font-sans: source-sans-pro, sans-serif;
    --fontweight-light: 300;
    --fontweight-normal: 400;
    --fontweight-semibold: 600;
    --fontweight-bold: 700;
    --fontsize-h1: clamp(2.3875rem, 4.75vw, 3rem);
    --fontsize-h2: clamp(2rem, 4.5vw, 2.75rem);
    --fontsize-h3: clamp(1.75rem, 4vw, 2.125rem);
    --fontsize-h4: clamp(1.75rem, 4vw, 2rem);
    --fontsize-h5: clamp(1.375rem, 3vw, 1.625rem);
    --fontsize-h6: clamp(1.375rem, 3vw, 1.625rem);
    --fontsize-p-xl: clamp(1.25rem, 1.75vw, 1.5rem);
    --fontsize-p-lg: clamp(1.125rem, 1.5vw, 1.25rem);
    --fontsize-p: rem(18px);

    // Typography, links
    --text-color: var(--brown);
    --link-color: var(--orange-dark);
    --link-color-hover: var(--orange);
    --link-color-visited: var(--purple-600);

    // Spacing
    --block-spacing-sm: 4rem;
    --block-spacing-md: 6rem;
    --block-spacing-lg: 8rem;
    --block-spacing-xl: 10rem;
    --block-spacing-xxl: 12rem;
    --block-spacing-sm-neg: -4rem;
    --block-spacing-md-neg: -6rem;
    --block-spacing-lg-neg: -8rem;
    --block-spacing-xl-neg: -10rem;
    --block-spacing-xxl-neg: -12rem;

    // Utility
    --red-600: #a50034;
    --purple-600: #402885;

    // Misc
    --border-radius: 20px;
}

// Breakpoints
$bp-xs: 30em;  // 480px
$bp-sm: 40em;  // 640px
$bp-md: 50em;  // 800px
$bp-lg: 60em;  // 960px
$bp-xl: 80em;  // 1280px
$bp-xxl: 90em; // 1440px
